import React, { useState } from "react";
import "./sass/style.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Accordion, Card, Button } from "react-bootstrap";
import { Link } from "gatsby";
import Plx from "react-plx";
import Cosmicdata from "./cosmicdata.js";
import Logo from "./small-logo.png";
import createParallaxConfig from '../layout/createParallaxConfig';
import ReactHtmlParser from "react-html-parser";
import Intelectual from "./intellectual.js";

export default function Mainhomepage() {
  const [accord, setaccord] = useState("");
  const result = Cosmicdata();
  console.log(result);
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: true,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var settings2 = {
    dots: false,

    speed: 500,
    arrows: false,
    slidesToShow: 1.5,
    infinite: false,
    slidesToScroll: 1,
  };

  var settings3 = {
    dots: true,

    speed: 500,
    arrows: false,
    slidesToShow: 1,
    infinite: false,
    slidesToScroll: 1,
  };

  return (
    <div className="wrap">
      <div className="header">
        <nav className="navbar navbar-expand-md navbar-light py-3" id="mainNav">
          <div className="container-fluid">
            <a className="navbar-brand js-scroll-trigger" href="#page-top">
              <img src={Logo} alt="MedAnswers Logo" />
            </a>
            <button
              className="navbar-toggler navbar-toggler-right collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="navbar-collapse collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto my-2 my-lg-0">
                <li className="nav-item">
                  <a
                    className="nav-link js-scroll-trigger"
                    href="#specialities"
                  >
                    Specialities{" "}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link js-scroll-trigger" href="#Teams">
                    Team{" "}
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a
                    className="nav-link js-scroll-trigger"
                    href="#intellectual"
                  >
                    Intellectual Property{" "}
                  </a>
                </li> */}
                <li className="nav-item">
                  <a className="nav-link js-scroll-trigger" href="#giving">
                    Giving Back
                  </a>
                </li>
                <li className="nav-item active">
                  <Link className="nav-link js-scroll-trigger"
                    to="https://medinsights.medanswers.com"
                    target="_blank">
                    Insights
                  </Link>
                </li>
                <li className="nav-item active">
                  <Link className="nav-link js-scroll-trigger" to="https://fertility.medanswers.com">
                    Fertility Answers
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <div className="hero-section">
        <div className="container">
          <div className="row">
            {result.allCosmicjsMedanswers && (
              <div className="col-lg-10 m-auto text-center">
                <div className="hero-caption">
                  <h1>
                    {" "}
                    {
                      result.allCosmicjsMedanswers.edges[0].node.metadata
                        .heading
                    }{" "}
                  </h1>
                  <p>
                    {
                      result.allCosmicjsMedanswers.edges[0].node.metadata
                        .subheading
                    }
                  </p>
                </div>
                <div className="row">
                  <div className="col-md-7 col-12">
                    <div className="large-thumb">
                      <Plx parallaxData={createParallaxConfig({ distance: 450 })}>
                        <img
                          className="img-fluid d-none d-xl-block d-lg-block d-md-block"
                          src={
                            result.allCosmicjsMedanswers.edges[0].node.metadata
                              .img_desktop.url
                          }
                          alt="introduction image"
                        />
                      </Plx>
                      <Plx parallaxData={createParallaxConfig({ distance: 300 })}>
                        <img
                          className="img-fluid d-block d-xl-none d-lg-none d-md-none"
                          src={
                            result.allCosmicjsMedanswers.edges[0].node.metadata
                              .img_mobile.url
                          }
                          alt="introduction image"
                        />
                      </Plx>
                    </div>
                  </div>
                  <div className="col-md-5 d-none d-xl-block d-lg-block d-md-block">
                    <div className="small-thumb">
                      <Plx parallaxData={createParallaxConfig({ distance: 200 })}>
                        <img
                          className="img-fluid"
                          src={
                            result.allCosmicjsMedanswers.edges[0].node.metadata
                              .support_img.url
                          }
                          alt="support image"
                        />
                      </Plx>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="proof-section">
        <div className="container">
          <div className="row flex-column-reverse flex-xl-row flex-lg-row flex-md-row">
            <div className="col-lg-5 col-md-6">
              <div className="mobile-image">
                <img
                  className="img-fluid"
                  src={require("./images/group-93x.png")}
                  alt="Fertility Answers app"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 offset-lg-1">
              <div className="proof-caption">
                <h5>
                  <img src={require("./images/group-10-copy3x.png")} alt="first release" />
                </h5>
                <h3>FertilityAnswers</h3>
                <p>
                  Our first release was FertilityAnswers which now has:
                  <br />
                  - 50% of US infertility clinics enaged
                  <br />
                  - 3000 new registered users a month
                  <br />
                  - Aggressive IP portfolio
                  <br />
                  - Revenue stage: growth
                  <br />
                  - 2M data points collected on users
                  <br />
                  - 89% of users opt-in for a fertility consult
                </p>
                <a target="_blank" href="https://fertility.medanswers.com">
                  Check it out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="case-studies">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h2>FERTILITYANSWERS CASE STUDIES </h2>
              <div className="carousel-slider">
                <Slider {...settings}>
                  {result.allCosmicjsMedanswerCaseStudies &&
                    result.allCosmicjsMedanswerCaseStudies.nodes.map((data) => {
                      return (
                        <div className="slider-box">
                          <div className="brand">
                            <img src={data.metadata.icons.url} alt="icon" />
                          </div>
                          <h4>{data.metadata.heading}</h4>

                          {ReactHtmlParser(data.metadata.description)}
                        </div>
                      );
                    })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="specialties" id="specialities">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 offset-lg-1">
              <h2>Specialties</h2>
              <p>
                The Covid-19 “infodemic”, a term coined by the World Health
                Organization (WHO) to describe the dangerous nature of
                misinformation in the face of a health crisis, underscores the
                utility of applying our technology assets to any specialty area
                of healthcare. Access to correct, unbiased, personalized health
                care information can be the difference between suffering and
                healing, affordable treatment or snake oil, and even can mean
                the difference between life and death. MedAnswers is seeking
                partners to rapidly expand into other health are verticals, a
                few of which are described below.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-10 col-12 m-auto d-flex flex-wrap">
              {result.allCosmicjsSpecialitiesMedanswers &&
                result.allCosmicjsSpecialitiesMedanswers.nodes.map((data) => {
                  return (
                    <div className="special-box">
                      <h5
                        style={{
                          backgroundColor: data.metadata.background_color,
                        }}
                      >
                        {data.metadata.heading}
                        <br />
                        Answers
                      </h5>
                      <div className="box-caption">
                        <p> {ReactHtmlParser(data.metadata.description)}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className=" col-12">
              <div className="mobile-view">
                <Slider {...settings2}>
                  {result.allCosmicjsSpecialitiesMedanswers &&
                    result.allCosmicjsSpecialitiesMedanswers.nodes.map(
                      (data) => {
                        return (
                          <div className="special-box">
                            <h5
                              style={{
                                backgroundColor: data.metadata.background_color,
                              }}
                            >
                              {data.metadata.heading}
                              <br />
                              Answers
                            </h5>
                            <div className="box-caption">
                              <p>
                                {ReactHtmlParser(data.metadata.description)}
                              </p>
                            </div>
                          </div>
                        );
                      }
                    )}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="team-section" id="Teams">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="mb-3">Leadership Team</h2>
              <p>
                Female led and majority owned, fully remote team with 6 exits &
                6 aquisitions.
              </p>
            </div>
            <div className="team-wrap">
              {result.allCosmicjsLeadershipTeams &&
                result.allCosmicjsLeadershipTeams.edges.map((data) => {
                  return (
                    <div className="team-thumb">
                      <img
                        className="img-fluid"
                        src={data.node.metadata.member_img.url}
                        alt="leadership member image"
                      />
                      <h6>{data.node.metadata.name}</h6>
                      <span>{data.node.metadata.designation}</span>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <h2 className="mb-md-5 mb-md-3">Our Investors and Advisors</h2>
            </div>
            <div className="team-wrap">
              {result.allCosmicjsInvestorTeams &&
                result.allCosmicjsInvestorTeams.edges.map((data) => {
                  return (
                    <div className="team-thumb">
                      <img
                        className="img-fluid"
                        src={data.node.metadata.investor_img.url}
                        alt="investor/advisor image"
                      />
                      <h6>{data.node.metadata.name}</h6>
                      <span>{data.node.metadata.designation}</span>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <h2 className="mb-md-5 mb-md-3">Reproductive Council</h2>
            </div>
            <div className="team-wrap mb-0 show-desktop">
              {result.allCosmicjsReproductiveCouncils &&
                result.allCosmicjsReproductiveCouncils.edges.map((data) => {
                  return (
                    <div className="team-thumb">
                      <img
                        className="img-fluid"
                        src={data.node.metadata.member_team.url}
                        alt="council member image"
                      />
                      <h6>{data.node.metadata.name}</h6>
                      <span>{data.node.metadata.designation}</span>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="row">
            <div className="council-mobile col-lg-12">
              <Slider {...settings2}>
                {result.allCosmicjsReproductiveCouncils &&
                  result.allCosmicjsReproductiveCouncils.edges.map((data) => {
                    return (
                      <div>
                        <div className="team-thumb">
                          <img
                            className="img-fluid"
                            src={data.node.metadata.member_team.url}
                            alt="council member image"
                          />
                          <h6>{data.node.metadata.name}</h6>
                          <span>{data.node.metadata.designation}</span>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className="about-us">
        <div className="container">
          <div className="client">
            <img src={require("./images/group-103x.png")} alt="person speaking" />
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <h3>People are talking about us</h3>
            </div>
          </div>
          <div className="row footer-ref-desktop">
            <div className="col-lg-3 col-md-6 text-center">
              <p>
                “If Alice has her way, she will ensure every patient seeking
                fertility answers will have that access. She is unstoppable.”
              </p>
              s{" "}
              <a
                target="_blank"
                href="https://www.forbes.com/sites/devinthorpe/2017/09/01/unstoppable-mom-cancer-survivor-creates-new-fertility-app/#fe06f637fd86"
              >
                <img
                  className="img-fluid m-auto forbes"
                  src={require("./images/forbes-logo-black-transparent-1024-x-2683x.png")}
                  alt="forbes logo"
                />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <p>
                Some of MedAnswers' opinions on various fertility edge cases
              </p>
              <a
                target="_blank"
                href="https://www.thehealthy.com/sex/semen-allergy-signs"
              >
                <img
                  className="img-fluid m-auto digest"
                  src={require("./images/2000-px-reader-s-digest-logo-svg3x.png")}
                  alt="reader's digest logo"
                />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <p>
                “Using big data and genetics to match would-be parents with a
                curated network of fertility experts and solutions to achieve
                healthier fertility outcomes, faster.”
              </p>
              <a
                target="_blank"
                href="https://techcrunch.com/2018/04/26/the-genomics-focused-illumina-accelerator-backs-five-new-companies"
              >
                <img
                  className="img-fluid m-auto tech"
                  src={require("./images/tech.png")}
                  alt="techcrunch logo"
                />
              </a>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <p>
                “Crisci founded Fertile Action, a cancer charity established to
                ensure fertile people touched by cancer can become mothers.”
              </p>
              <a href="https://beta.prx.org/stories/212489" target="_blank">
                <img
                  className="img-fluid m-auto oneco"
                  src={require("./images/oncozine-270-x-903x.png")}
                  alt="oncozine logo"
                />
              </a>
            </div>
          </div>
          <div class="footer-ref">
            <div className="col-xs-12">
              <Slider {...settings3}>
                <div className=" text-center">
                  <p>
                    “If Alice has her way, she will ensure every patient seeking
                    fertility answers will have that access. She is
                    unstoppable.”
                  </p>
                  s{" "}
                  <a
                    target="_blank"
                    href="https://www.forbes.com/sites/devinthorpe/2017/09/01/unstoppable-mom-cancer-survivor-creates-new-fertility-app/#fe06f637fd86"
                  >
                    <img
                      className="img-fluid m-auto forbes"
                      src={require("./images/forbes-logo-black-transparent-1024-x-2683x.png")}
                      alt="forbes logo"
                    />
                  </a>
                </div>
                <div className="text-center">
                  <p>
                    “Proin venenatis, sem a lobortis rhoncus, turpis ex
                    ultricies justo”
                  </p>
                  <a
                    target="_blank"
                    href="https://www.thehealthy.com/sex/semen-allergy-signs"
                  >
                    <img
                      className="img-fluid m-auto digest"
                      src={require("./images/2000-px-reader-s-digest-logo-svg3x.png")}
                      alt="reader's digest logo"
                    />
                  </a>
                </div>
                <div className="text-center">
                  <p>
                    “Using big data and genetics to match would-be parents with
                    a curated network of fertility experts and solutions to
                    achieve healthier fertility outcomes, faster.”
                  </p>
                  <a
                    target="_blank"
                    href="https://techcrunch.com/2018/04/26/the-genomics-focused-illumina-accelerator-backs-five-new-companies"
                  >
                    <img
                      className="img-fluid m-auto tech"
                      src={require("./images/tech.png")}
                      alt="techcrunch logo"
                    />
                  </a>
                </div>
                <div className=" text-center">
                  <p>
                    “Crisci founded Fertile Action, a cancer charity established
                    to ensure fertile people touched by cancer can become
                    mothers.”
                  </p>
                  <a href="https://beta.prx.org/stories/212489" target="_blank">
                    <img
                      className="img-fluid m-auto oneco"
                      src={require("./images/oncozine-270-x-903x.png")}
                      alt="oncozine logo"
                    />
                  </a>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
      {/*  <div className="property-section" id="intellectual">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 m-auto">
              <h2>Intelectual Property</h2>
              <p>
                We have an aggressive IP strategy that includes a patent on
                non-invasive, comprehensive, hormone testing and provisional
                filings on our technology and genetic integrations, as well as
                trademarks as applicable.
              </p>
              <div id="accordion">
                {result.allCosmicjsIntelectualProperties &&
                  result.allCosmicjsIntelectualProperties.edges.map(
                    (data, counter) => {
                      return (
                        <Accordion
                          defaultActiveKey={counter}
                          className={
                            accord === counter.toString() ? "active" : null
                          }
                          onClick={() => {
                            if (accord === counter.toString()) {
                              setaccord("");
                            } else {
                              setaccord(counter.toString());
                            }
                          }}
                        >
                          <Card>
                            <Card.Header>
                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="0"
                              >
                                {data.node.metadata.title}
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse
                              className="check"
                              eventKey={"0"}
                            >
                              <Card.Body>
                                {" "}
                                {data.node.metadata.description}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      );
                    }
                  )}
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Intelectual></Intelectual>
      <div className="giveback-section" id="giving">
        <div className="container">
          <div className="row px-0">
            <div className="col-xl-10 m-auto">
              <h2>
                Giving <span>back</span>
              </h2>
              <div className="row m-0 flex-nowrap position-relative">

                <div className="left-thumb">
                  <Plx parallaxData={createParallaxConfig({ distance: 400 })}>
                    <img
                      className="img-fluid"
                      src={require("./images/alice-101514-00493x.png")}
                      alt="picture of co-founder alice"
                    />
                  </Plx>
                </div>
                <div className="middle-thumb">
                  <Plx parallaxData={createParallaxConfig({ distance: 150 })}>
                    <img
                      className="img-fluid"
                      src={require("./images/major-tom-agency-k-8-lpw-m-xc-as-unsplash3x.png")}
                      alt="people doing ALS ice bucket challenge"
                    />
                  </Plx>
                </div>
                <div className="right-thumb d-none d-xl-block d-lg-block d-md-block">
                  <Plx parallaxData={createParallaxConfig({ distance: 200 })}>
                    <img
                      className="img-fluid"
                      src={require("./images/ty-lagalo-sfe-n-q-mz-2-h-0-unsplash3x.png")}
                      alt="children holding hands"
                    />
                  </Plx>
                </div>
              </div>
            </div>
          </div>
          <div className="row flex-column-reverse flex-xl-row flex-lg-row flex-md-row">
            <div className="col-lg-4 col-md-6">
              <div className="giveaway-heading">
                <h5>Ways we give back</h5>
              </div>
              <ul className="giveawy-list">
                <li>
                  <img src={require("./images/group-10-copy.png")} alt="bullet" />
                  Employees have unlimited volunteer days{" "}
                </li>
                <li>
                  <img src={require("./images/group-11-copy.png")} alt="bullet" />
                  Participation in legislative advocacy{" "}
                </li>
                <li>
                  <img src={require("./images/group-12-copy.png")} alt="bullet" />
                  Strategic partnerships with multiple charities
                </li>
                <li>
                  <img src={require("./images/group-12-copy-2.png")} alt="bullet" />
                  Providing free access forever to personalized education
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-6 offset-lg-1">
              <div className="giveaway-detail">
                <p>
                  Our first vertical, FertilityAnswers, was started by fertility
                  patients, advocates, researchers and physicians who serve the
                  market. We've worked alongside advocacy organizations and
                  non-profits to increase access to care, better serve patients
                  and democratize pricing. Volunteerism is a core part of every
                  disease state in which humans are suffering. We strongly
                  encourage our employees and partners to lend their voice to
                  worthy causes, to make a difference in their daily actions and
                  open their hearts to giving back.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 m-auto text-center">
              <h3>Interested in joining the team or investing?</h3>
              <a target="_blank" href="/contact/index.html">
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-6">
              <div className="ftr-logo">
                <img
                  src={require("./images/med-answers-logo-lockup-horizontal-full-color.png")}
                  alt="MedAnswers Logo"
                />
              </div>
              <div className="social-icon">
                <h4>Follow</h4>
                <ul>
                  <li>
                    <a
                      href="https://www.instagram.com/fertilityanswers_/"
                      targte="_blank"
                    >
                      <img src={require("./images/insta.png")} alt="Instagram Link - Link to Instagram" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/medanswers.fertility"
                      targte="_blank"
                    >
                      <img src={require("./images/fb.png")} alt="Facebook Logo - Link to Facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/Medanswers_IVF"
                      targte="_blank"
                    >
                      <img src={require("./images/twitter.png")} alt="Twitter Logo - Link to Twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/medanswers/"
                      targte="_blank"
                    >
                      <img src={require("./images/linkedin.png")} alt="LinkedIn Logo - Link to LinkedIn" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCYH7kzYmFiMAWJOar2ORj9g"
                      targte="_blank"
                    >
                      <img src={require("./images/youtube.png")} alt="Youtube Logo - Link to Youtube Channel" />
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <img
                        src={require("./images/pinterest-logo-e-994-f-3-a-9-fb-seeklogo-com.png")}
                        alt="Pinterest logo - Link to Pinterest"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="ftr-right">
                <h6>Download FertilityAnswers</h6>
                <div className="app-store">
                  <a href="https://play.google.com/store/apps/details?id=com.fertilityanswers">
                    <img src={require("./images/google-play.png")} alt="Apple Store Logo - Link to Apple Store App" />
                  </a>
                  <br />
                  <a href="https://apps.apple.com/us/app/fertilityanswers/id1418650176">
                    <img src={require("./images/apple-store.png")} alt="Play Store Logo - Link to Play Store App" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
