import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";
import MainHome from "../components/MainHome/index.js";
import { watchloading } from "../state/app.js";
import { useDispatch, useSelector } from "react-redux";
import "../components/layout/assets/css/loader.scss";

export default function IndexPage() {
  return (
    <>
      <SEO
        title="Trusted Experts. Answers they agree on."
        description="We are ending the spread of misinformation one healthcare specialty at a time."
        keywords={[`medical answers`, `medical questions`, `medanswers`]}
      />
      <MainHome />
    </>
  );
}
